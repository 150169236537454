<template>
  <div>
    <el-dialog
      :title="getDialogTitle"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="employeesForm">
        <el-form-item :label="t('employees.name')" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="t('employees.mobile')" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="t('employees.gender')" prop="gender">
          <!-- <el-input v-model="ruleForm.gender"></el-input> -->
          <el-radio-group v-model="ruleForm.gender" style="width: 100%">
            <el-radio
              v-for="item in options"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-row type="flex" :gutter="20">
          <el-col :span="8">
            <el-form-item :label="t('employees.province')" prop="province">
              <el-input v-model="ruleForm.province"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="t('employees.city')" prop="city">
              <el-input v-model="ruleForm.city"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="t('employees.region')" prop="region">
              <el-input v-model="ruleForm.region"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item :label="t('employees.address')" prop="address">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, watch, getCurrentInstance, computed } from "vue";
export default {
  name: "employees_dialog",
  props: {
    row: Object,
    type: String,
    options: Array,
  },
  setup(props) {
    const { t } = useI18n();
    const { proxy } = getCurrentInstance();
    const dialogVisible = ref(false);

    const ruleForm = reactive({
      name: "",
      mobile: "",
      gender: "",
      province: "",
      city: "",
      region: "",
      address: "",
    });

    const employeesForm = ref(null);

    const rules = reactive({
      name: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      mobile: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      gender: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      province: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      city: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      region: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      address: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      employeesForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      employeesForm.value.validate((valid) => {
        if (valid) {
          if (props.type === "create") {
            proxy.$emit("submit", ruleForm);
          } else {
            proxy.$emit("edit", ruleForm);
          }
        }
      });
    };

    const handleClose = () => {
      employeesForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const openDialog = () => {
      employeesForm.value?.resetFields();
      dialogVisible.value = true;
    };

    const closeDialog = () => {
      dialogVisible.value = false;
    };

    watch(props, () => {
      if (props.type !== "create") {
        ruleForm.id = props.row.id;
        ruleForm.name = props.row.name ? props.row.name : "";
        ruleForm.mobile = props.row.mobile ? props.row.mobile : "";
        ruleForm.gender = props.row.gender ? props.row.gender : 0;
        ruleForm.province = props.row.province ? props.row.province : "";
        ruleForm.city = props.row.city ? props.row.city : "";
        ruleForm.region = props.row.region ? props.row.region : "";
        ruleForm.address = props.row.address ? props.row.address : "";
      } else {
        ruleForm.name = "";
        ruleForm.mobile = "";
        ruleForm.gender = 0;
        ruleForm.province = "";
        ruleForm.city = "";
        ruleForm.region = "";
        ruleForm.address = "";
      }
    });

    const getDialogTitle = computed(() => {
      switch (props.type) {
        case "create":
          return t("common.create");
        case "detail":
          return t("common.detail");
        default:
          return t("common.edit");
      }
    });

    return {
      t,
      dialogVisible,
      employeesForm,
      rules,
      ruleForm,
      openDialog,
      closeDialog,
      handleClose,
      resetForm,
      submitForm,
      getDialogTitle,
    };
  },
};
</script>

<style>
</style>
