<template>
  <div class="content">
    <el-row type="flex" align="middle" class="search">
      <el-col :span="8">
        <el-input
          size="medium"
          v-model="params.keyword"
          :placeholder="t('common.search_placeholder')"
        ></el-input>
      </el-col>
      <el-col :span="2" :offset="1">
        <el-button size="small" type="primary" @click="handleSearch">{{
          t("common.search")
        }}</el-button>
      </el-col>
      <el-col :span="2">
        <el-button
          size="small"
          type="primary"
          @click="showDialog(null, 'create')"
          >{{ t("common.create") }}</el-button
        >
      </el-col>
      <el-col :span="2">
        <upload-excel-component
          :on-success="handleSuccess"
          :before-upload="beforeUpload"
        />
      </el-col>
    </el-row>

    <el-table :data="tableInfo.data">
      <el-table-column
        type="index"
        width="80"
        :label="t('common.number')"
      ></el-table-column>
      <el-table-column
        prop="name"
        :label="t('employees.name')"
      ></el-table-column>
      <el-table-column
        prop="mobile"
        :label="t('employees.mobile')"
      ></el-table-column>
      <el-table-column prop="gender" :label="t('employees.gender')">
        <template #default="scope">
          <span>{{
            scope.row.gender === 1
              ? "男"
              : scope.row.gender === 2
              ? "女"
              : "未知"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="province"
        :label="t('employees.province')"
      ></el-table-column>
      <el-table-column
        prop="city"
        :label="t('employees.city')"
      ></el-table-column>
      <el-table-column
        prop="region"
        :label="t('employees.region')"
      ></el-table-column>
      <el-table-column
        prop="address"
        :label="t('employees.address')"
      ></el-table-column>
      <el-table-column fixed="right" :label="t('common.action')" width="120">
        <template #default="scope">
          <el-button
            @click="showDialog(scope.row, 'detail')"
            type="text"
            size="small"
            >{{ t("common.detail") }}</el-button
          >
          <el-button
            @click="showDialog(scope.row, 'update')"
            type="text"
            size="small"
            >{{ t("common.edit") }}</el-button
          >
          <el-button
            @click="handleDelete(scope.row)"
            type="text"
            class="text"
            size="small"
            >{{ t("common.delete") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="pagination"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableInfo.pagination.page"
      :page-sizes="[20, 40, 60]"
      :page-size="tableInfo.pagination.per_page"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableInfo.pagination.total_entities"
    >
    </el-pagination>

    <EmployeesDialog
      ref="employeesDialog"
      :type="dialogType"
      :row="currentRow"
      :options="genderOptions"
      @submit="handleCreate"
      @edit="handleEdit"
    ></EmployeesDialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, onMounted, ref } from "vue";
import { ElMessage } from "element-plus";
import {
  search,
  get,
  create,
  update,
  remove,
  syncEmployees,
} from "@/api/employees";
import EmployeesDialog from "./employees_dialog.vue";
import UploadExcelComponent from "@/components/UploadExcel/index.vue";
export default {
  name: "employees",
  components: {
    EmployeesDialog,
    UploadExcelComponent,
  },
  setup() {
    const { t } = useI18n();

    const params = reactive({
      page: 1,
      per_page: 20,
      keyword: "",
    });

    const genderOptions = reactive([
      {
        label: "未知",
        value: 0,
      },
      {
        label: "男",
        value: 1,
      },
      {
        label: "女",
        value: 2,
      },
    ]);

    const dialogType = ref(null);
    const currentRow = ref({});
    const employeesDialog = ref(null);

    let tableInfo = reactive({
      data: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_entities: 0,
        total_pages: 0,
      },
    });

    const showDialog = (row, type) => {
      dialogType.value = type;
      currentRow.value = row;

      employeesDialog.value.openDialog();
    };

    const handleCreate = async (row) => {
      const res = await create(row);
      if (res.error_code === 0) {
        employeesDialog.value.closeDialog();
        ElMessage.success(t("common.create_prompt"));
        getEmployeesData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDetail = async (row) => {
      const res = await get({ id: row.id });
      console.log("res", res);
    };

    const handleEdit = async (row) => {
      const res = await update(row);
      if (res.error_code === 0) {
        employeesDialog.value.closeDialog();
        ElMessage.success(t("common.update_prompt"));
        getEmployeesData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleDelete = async (row) => {
      const res = await remove({ id: row.id });
      if (res.error_code === 0) {
        ElMessage.success(t("common.delete_prompt"));
        getEmployeesData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const handleSearch = (params) => {
      console.log("params", params);
      getEmployeesData();
    };

    const handleSizeChange = (val) => {
      params.per_page = val;
      getEmployeesData();
    };

    const handleCurrentChange = (val) => {
      params.page = val;
      getEmployeesData();
    };

    const getEmployeesData = async () => {
      const res = await search(params);
      if (res.error_code === 0) {
        tableInfo.data = res.response.data;
        tableInfo.pagination = res.response.pagination;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const beforeUpload = (file) => {
      const isLt100M = file.size / 1024 / 1024 < 100;

      if (isLt100M) {
        return true;
      }

      ElMessage.error({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    };

    const handleSuccess = ({ results, header }) => {
      // 需要处理excel中没有相关字段时，产生的错误
      console.log("header", header);

      if (
        header.includes("姓名") &&
        header.includes("性别") &&
        header.includes("手机号") &&
        header.includes("省份") &&
        header.includes("市") &&
        header.includes("区") &&
        header.includes("详细地址")
      ) {
        var newResults = results.map((old) => {
          return {
            name: old["姓名"],
            gender: old["性别"] === "男" ? 1 : old["性别"] === "女" ? 2 : 0,
            mobile: String(old["手机号"]),
            province: old["省份"],
            city: old["市"],
            region: old["区"],
            address: old["详细地址"],
          };
        });

        console.log("info", newResults);

        handleSyncEmployees({ employees: newResults });
      } else {
        ElMessage.error(t("survey.prompt"));
      }
    };

    const handleSyncEmployees = async (obj) => {
      const res = await syncEmployees(obj);
      if (res.error_code === 0) {
        ElMessage.success(t("common.sync_prompt"));
        getEmployeesData();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getEmployeesData();
    });
    return {
      t,
      params,
      tableInfo,
      employeesDialog,
      currentRow,
      showDialog,
      dialogType,
      handleCreate,
      handleDetail,
      handleEdit,
      handleDelete,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      beforeUpload,
      handleSuccess,
      genderOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0px 20px 0px 20px;
}

.search {
  margin-top: 20px;
}

.text {
  color: #f56c6c;
}
.pagination {
  float: right;
  margin: 20px 0px 20px 0px;
}
</style>
