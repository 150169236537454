import service from '@/utils/request'

// 获取Employees列表
export const search = (params) => {
    return service({
        url: '/employees',
        method: 'get',
        params
    })
}

// 获取指定Employees的详情
export const get = (params) => {
    return service({
        url: `/employee/${params.id}`,
        method: 'get'
    })
}

// 创建Employees
export const create = (data) => {
    return service({
        url: '/employee',
        method: 'post',
        data
    })
}

// 更新Employees信息
export const update = (data) => {
    return service({
        url: `/employee/${data.id}`,
        method: 'put',
        data
    })
}

// 删除Employees
export const remove = (data) => {
    return service({
        url: `/employee/${data.id}`,
        method: 'delete',
        data
    })
}

// excel 导入Employees信息
export const syncEmployees = (data) => {
    return service({
        url: '/sync/employees',
        method: 'post',
        data
    })
}
